import { SvgIcon } from '@mui/material'

const InstagramIcon = (props) => (
  <SvgIcon {...props} alt="Instagram">
    <path
      d="M17.24 0.75H6.73999C3.42628 0.75 0.73999 3.43629 0.73999 6.75V17.25C0.73999 20.5637 3.42628 23.25 6.73999 23.25H17.24C20.5537 23.25 23.24 20.5637 23.24 17.25V6.75C23.24 3.43629 20.5537 0.75 17.24 0.75Z"
      fill="none"
      stroke="#F9F9F6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.99 17C14.7514 17 16.99 14.7614 16.99 12C16.99 9.23858 14.7514 7 11.99 7C9.22857 7 6.98999 9.23858 6.98999 12C6.98999 14.7614 9.22857 17 11.99 17Z"
      fill="none"
      stroke="#F9F9F6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.375 6C18.5821 6 18.75 5.83211 18.75 5.625C18.75 5.41789 18.5821 5.25 18.375 5.25C18.1679 5.25 18 5.41789 18 5.625C18 5.83211 18.1679 6 18.375 6Z"
      fill="none"
      stroke="#F9F9F6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export default InstagramIcon
