import {
  Box,
  Container,
  IconButton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import Image from 'next/image'
import { FunctionComponent, useContext, useRef } from 'react'
import { ReactNode } from 'react-markdown/lib/react-markdown'
import { APP_STORE_URL, CAREERS_URL } from 'lib/constants'
import { phoneNumbers } from 'lib/phoneNumbers'
import { isNotBoston } from 'lib/utils'
import { colors } from '../lib/root'
import appleAppStoreBadge from '../public/apple-app-store-badge.svg'
import tendLetterLogo from '../public/tend-letter-logo.svg'
import Link from './Link'
import { AppContext } from './contexts/AppContext'
import usePatientFetcher from './hooks/usePatientFetcher'
import FacebookIcon from './icons/FacebookIcon'
import InstagramIcon from './icons/InstagramIcon'
import TwitterIcon from './icons/TwitterIcon'

const ContactLink = ({
  val,
  email = false,
  sx,
}: {
  val: string
  email?: boolean
  sx?: SxProps<Theme>
}) => (
  <Link
    href={email ? `mailto:${val}` : `tel:${val}`}
    aria-label={`For questions ${email ? 'email' : 'call'} ${val}`}
    sx={sx}
  >
    {val}
  </Link>
)

const InvisalignLegalDisclaimer = () => (
  <Typography
    fontSize="0.75rem !important"
    lineHeight={1.2}
    mt={2.5}
    color={colors.core.green[300]}
    letterSpacing={'0.03125rem'}
  >
    Invisalign®, the Invisalign logo, and iTero®, among others, are trademarks
    and/or service marks of Align Technology, Inc. or one of its subsidiaries or
    affiliated companies and may be registered in the U.S. and/or other
    countries.
  </Typography>
)

interface Props {
  slim?: boolean
  phoneNumber?: string
  hideDisclaimer?: boolean
  children?: ReactNode
}

const Footer: FunctionComponent<Props> = ({
  slim = false,
  phoneNumber = phoneNumbers.default,
  hideDisclaimer = false,
  children,
}) => {
  const { email, isWebView } = useContext(AppContext)
  const currentYear = new Date().getFullYear()
  const footerRef = useRef<HTMLDivElement>()
  const { data: patient } = usePatientFetcher()

  const showInviteFriend = !!patient
    ? isNotBoston(patient?.person?.market)
    : true
  const inviteFriendLink = !!patient
    ? '/profile/invite-a-friend'
    : '/invite-a-friend'

  if (isWebView) {
    return null
  }

  const headingStyles = {
    color: colors.core.green[300],
    fontSize: '2rem !important',
    mb: { xs: 2.25, sm: 3.5 },
    pb: { sm: 3.5 },
    borderBottom: { sm: `1px solid ${colors.core.green[600]}` },
  }

  const socialLinksStyles = {
    m: 0,
    p: 0,
    listStyle: 'none',
    display: 'flex',
    alignItems: 'center',
    '& li': {
      mr: 1,
      '& a': {
        display: 'flex',
        color: colors.core.paper,
      },
    },
    '& svg': {
      maxHeight: '100%',
    },
  }

  const contactHeadingStyles = {
    fontSize: '1.125rem !important',
    lineHeight: 1.5,
    color: colors.core.green[300],
  }

  const contactLinkStyles = {
    display: 'block',
    fontSize: '1.125rem !important',
    lineHeight: 1.5,
    color: colors.core.paper,
    '&:not(:last-child)': {
      pb: 3.5,
    },
  }

  return (
    <footer ref={footerRef}>
      <Box
        sx={{
          pt: { xs: 5, sm: 5.5 },
          pb: { xs: slim ? 5 : 8.25, sm: 3 },
          px: { xs: 3, md: 8 },
          color: 'text.primary',
          bgcolor: colors.core.green[700],
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            px: { xs: 0, xl: 8 },
          }}
        >
          {slim ? (
            <Box
              sx={{
                '& a, & p': {
                  display: 'inline-block',
                },
                '& a + a': {
                  pl: 2,
                },
                '& h2': {
                  mb: 1.5,
                  pb: 0,
                  border: 'none',
                },
              }}
            >
              <Typography component="h2" variant="h3" sx={headingStyles}>
                Questions?
              </Typography>
              <Box
                sx={{
                  fontSize: '1.125rem !important',
                  '& a': {
                    color: colors.core.paper,
                  },
                }}
              >
                <ContactLink val={phoneNumber} />
                <Typography
                  sx={{
                    fontSize: '1.125rem !important',
                    color: colors.core.green[300],
                    px: 1.5,
                  }}
                >
                  |
                </Typography>
                <ContactLink val={email} email />
              </Box>
            </Box>
          ) : (
            <Grid
              container
              spacing={8}
              sx={{
                width: '100%',
              }}
            >
              <Grid xs={12} sm={6} md={4}>
                <Typography component="h2" variant="h3" sx={headingStyles}>
                  Get the app
                </Typography>
                <Typography
                  sx={{
                    fontSize: '1.125rem !important',
                    lineHeight: 1.5,
                    mb: 1.5,
                    color: colors.core.green[300],
                  }}
                >
                  Stay in-the-know with the Tend Dental app:
                </Typography>
                <Box
                  component="ul"
                  sx={[
                    socialLinksStyles,
                    {
                      '& li': {
                        mr: 2,
                      },
                    },
                  ]}
                >
                  <li>
                    <Link
                      href={APP_STORE_URL}
                      underline="none"
                      target="_blank"
                      aria-label="Apple App Store (opens new window)"
                      rel="noopener noreferrer"
                    >
                      <Image
                        src={appleAppStoreBadge}
                        alt="Apple App Store badge"
                      />
                    </Link>
                  </li>
                </Box>
                <Box sx={{ display: { xs: 'none', sm: ' block' }, mt: 3.5 }}>
                  <Image
                    src="/mobile-app-qr-code.svg"
                    alt="Mobile app QR code"
                    width={124}
                    height={124}
                  />
                </Box>
              </Grid>
              <Grid xs={12} sm={6} md={4}>
                <Typography component="h2" variant="h3" sx={headingStyles}>
                  Questions?
                </Typography>
                <Box>
                  <Typography
                    component="h3"
                    variant="body1"
                    sx={contactHeadingStyles}
                  >
                    Phone
                  </Typography>
                  <ContactLink val={phoneNumber} sx={contactLinkStyles} />
                  <Typography
                    component="h3"
                    variant="body1"
                    sx={contactHeadingStyles}
                  >
                    Email
                  </Typography>
                  <ContactLink val={email} email sx={contactLinkStyles} />
                  <Typography
                    component="h3"
                    variant="body1"
                    sx={contactHeadingStyles}
                  >
                    Social
                  </Typography>
                  <Box component="ul" sx={socialLinksStyles}>
                    <li>
                      <IconButton
                        component={Link}
                        href="https://www.facebook.com/HelloTend"
                        target="_blank"
                        aria-label="Facebook (opens new window)"
                        rel="noopener noreferrer"
                        size="large"
                        edge="start"
                      >
                        <FacebookIcon />
                      </IconButton>
                    </li>
                    <li>
                      <IconButton
                        component={Link}
                        href="https://www.instagram.com/hello_tend/"
                        target="_blank"
                        aria-label="Instagram (opens new window)"
                        rel="noopener noreferrer"
                        size="large"
                      >
                        <InstagramIcon />
                      </IconButton>
                    </li>
                    <li>
                      <IconButton
                        component={Link}
                        href="https://www.twitter.com/tenddental/"
                        target="_blank"
                        aria-label="Twitter (opens new window)"
                        rel="noopener noreferrer"
                        size="large"
                        edge="end"
                      >
                        <TwitterIcon />
                      </IconButton>
                    </li>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} sm={12} md={4}>
                <Typography component="h2" variant="h3" sx={headingStyles}>
                  All about Tend
                </Typography>
                {children ? (
                  <>{children}</>
                ) : (
                  <Box
                    sx={{
                      columnCount: { xs: 2, sm: 4, md: 2 },
                      '& a': {
                        display: 'block',
                        fontSize: '1.125rem',
                        lineHeight: 2,
                        color: 'common.white',
                      },
                    }}
                  >
                    <Link href="/faq">FAQ</Link>
                    <Link href="/about">About</Link>
                    <Link href="/leadership">Leadership</Link>
                    <Link href="/press">Press</Link>
                    <Link href="/insurance">Insurance</Link>
                    <Link href="/studios">Locations</Link>
                    <Link href="/thegargler">The Gargler</Link>
                    {showInviteFriend && (
                      <Link href={inviteFriendLink}>Invite a Friend</Link>
                    )}

                    <Link href={CAREERS_URL}>Careers</Link>
                    <Link href="/services">All Services</Link>
                    <Link href="/checkup">Dental Exam</Link>
                    <Link href="/breezy-braces">Breezy Braces®</Link>
                    <Link href="/invisalign">Invisalign® Aligners</Link>
                    <Link href="/veneers">Veneers</Link>
                    <Link href="/whitening">Whitening</Link>
                  </Box>
                )}
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
      <Box
        component="section"
        sx={{
          pt: 3.25,
          px: { xs: 3, md: 8 },
          pb: { xs: 3.75, sm: 2.5 },
          fontSize: '1.125rem !important',
          lineHeight: 2,
          bgcolor: colors.core.green[800],
          color: colors.core.green[300],
          '& a': {
            display: { xs: 'block', sm: 'inline' },
            color: { xs: colors.core.green[300], sm: colors.core.grey[300] },
            mr: { sm: 3 },
          },
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            px: { xs: 0, xl: 8 },
          }}
        >
          <Grid container alignItems="flex-end">
            <Grid xs={6} sm={10}>
              <Link href="/terms">Terms</Link>
              <Link href="/terms#privacy">Privacy Policy</Link>
              {!slim && (
                <>
                  <Link href="/accessibility">Accessibility</Link>
                  <Link href="mailto:press@hellotend.com">Press Inquiries</Link>
                </>
              )}
              <Link href="/sitemap">Sitemap</Link>
            </Grid>
            <Grid xs>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  color: colors.core.green[300],
                  '& > div:first-of-type': {
                    mr: 2.25,
                  },
                }}
              >
                <Box sx={{ whiteSpace: 'nowrap' }}>© Tend, {currentYear}</Box>
                <Image src={tendLetterLogo} alt="Tend letter logo" />
              </Box>
            </Grid>
          </Grid>
          {!hideDisclaimer && <InvisalignLegalDisclaimer />}
        </Container>
      </Box>
    </footer>
  )
}

export default Footer
