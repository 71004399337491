import { Box, ButtonBase, ButtonBaseProps } from '@mui/material'
import { FunctionComponent } from 'react'
import { toSxArray } from 'lib/utils'

interface Props extends ButtonBaseProps {
  width?: number
  lineHeight?: number
  lineSpacing?: number
  color?: string
  borderRadius?: number
  padding?: string
  active?: boolean
}

const BurgerButton: FunctionComponent<Props> = ({
  onClick,
  active = false,
  width = 40,
  lineHeight = 4,
  lineSpacing = 6,
  color = '#000',
  borderRadius = 0,
  padding = '15px',
  sx,
}) => {
  const burgerInnerStyles = [
    {
      top: '50%',
      mt: `${(lineHeight / 2) * -1}px`,
      '&:before, &:after': {
        content: "''",
        display: 'block',
      },
      '&, &:before, &:after': {
        position: 'absolute',
        width: '100%',
        backgroundColor: color,
        height: lineHeight,
        borderRadius,
        transitionProperty: 'transform',
        transitionDuration: '0.15s',
        transitionTimingFunction: 'ease',
      },
      '&:before': {
        top: lineSpacing + lineHeight * -1,
        content: 'unset', // Change 3 bars to 2
      },
      '&:after': {
        bottom: (lineSpacing + lineHeight) * -1,
      },
    },
    {
      top: lineHeight / 2,
      '&:before': {
        top: lineSpacing + lineHeight,
        transitionProperty: 'transform, opacity',
        transitionTimingFunction: 'ease',
        transitionDuration: '0.15s',
      },
      '&:after': {
        top: (lineSpacing + lineHeight) * 2,
      },
    },
    active && {
      transform: `translate3d(0, ${
        lineSpacing + lineHeight
      }px, 0) rotate(45deg)`,
      '&:before': {
        transform: `rotate(-45deg) translate3d(${width / -7}px, ${
          lineSpacing * -1
        }px, 0)`,
        opacity: 0,
      },
      '&:after': {
        transform: `translate3d(0, ${
          (lineSpacing + lineHeight) * -2
        }px, 0) rotate(-90deg)`,
      },
    },
  ]

  return (
    <ButtonBase
      aria-label="Open the menu"
      onClick={onClick}
      sx={[
        {
          padding,
          display: 'flex',
          cursor: 'pointer',
          font: 'inherit',
          color: 'inherit',
          textTransform: 'none',
          bgcolor: 'transparent',
          border: 0,
          margin: 0,
          overflow: 'visible',
        },
        ...toSxArray(sx),
      ]}
    >
      <Box
        component="span"
        sx={{
          width,
          height: lineSpacing * 2 + lineHeight * 3,
          display: 'inline-flex',
          position: 'relative',
        }}
      >
        <Box component="span" sx={burgerInnerStyles} />
      </Box>
    </ButtonBase>
  )
}
export default BurgerButton
