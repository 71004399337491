import { Link } from '@mui/material'
import { colors } from 'lib/root'

const SkipNavLink = ({ contentId }: { contentId: string }) => (
  <Link
    href={`#${contentId}`}
    sx={{
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: '1px',
      width: '1px',
      m: '-1px',
      p: 0,
      overflow: 'hidden',
      position: 'absolute',
      '&:focus': {
        position: 'fixed',
        p: 1,
        top: 1,
        left: 1,
        bgcolor: colors.core.paper,
        zIndex: 100,
        width: 'auto',
        height: 'auto',
        clip: 'auto',
      },
    }}
  >
    Skip to content
  </Link>
)

export default SkipNavLink
