import { AxiosPromise } from 'axios'
import { IncomingMessage } from 'http'
import { GetServerSidePropsContext, NextPageContext } from 'next'
import queryString from 'query-string'
import { DeepPartial } from 'ts-essentials'
import { callApi } from '../api'
import { Appointment } from './appointments'
import { Insurance } from './insurance'
import { MedicalAlert } from './medical-alerts'
import { PatientPreference } from './patient-preferences'

export type PatientType = 'NEW' | 'EXIST'

interface CreateNote {
  body: string
}

export interface RecallPatient {
  id: string
  first_name: string
  last_name: string
  display_name: string
  email: string
  phone: string
  is_self_pay: boolean
  preferred_name: string
  active_insurance: Insurance
}

export type AddressKind =
  | 'DENTRIX'
  | 'WORK'
  | 'DENTRIX_ARCHIVE'
  | 'OTHER'
  | 'SHIPPING'

export interface Address {
  address1: string
  address2?: string
  city: string
  state: string
  zip: string
  kind: AddressKind
  id: string
  created_at: string
  updated_at: string
  name?: string
  is_default: boolean
}

export type AffirmInfo = {
  has_pre_qualified_for_affirm: boolean
  pre_qualification_for_affirm_expires_at: string
  amount_approved_by_affirm: number
  has_failed_affirm_qualification: boolean
  affirm_qualification_fail_expires_at: string
}

export interface Patient {
  has_insurance: boolean
  has_medical_info: boolean
  has_preferences: boolean
  has_realcloud_images: boolean
  is_self_pay: boolean
  can_remove_all_payment_methods: boolean
  created_at: string
  has_bookable_tx_plan_visit: boolean
  has_incomplete_tx_plan_visit: boolean
  has_incomplete_srp_tx_plan_visit: boolean
  chart_number: string
  patient_type: PatientType
  profile_photo_url: string
  preferred_name?: string // Note: preferred_name should appear here (outside of 'person') if using v1 patient endpoints
  market?: string
  smile_selfie_help_was_viewed_in_web?: boolean
  person: {
    first_name: string
    id: string
    designations?: string[]
    display_name: string
    last_name: string
    legal_first_name: string
    legal_last_name: string
    date_of_birth: string
    email: string
    primary_phone: string
    stripe_customer_id: string
    addresses: Address[]
    preferred_name?: string
    employer_name?: string
    job_title?: string
    market?: string
    last_mobile_app_login: string
    has_sms_short_code_consent: boolean
  }
  affirm_related_info: AffirmInfo
  meta: {
    heard_about_us_from: string
    address: Address
  }
  stripe_customer_id?: string
  will_cc_expire_before_next_appointment?: boolean
}

export interface PublicPatientRequest {
  id?: string
  first_name: string
  last_name: string
  preferred_name?: string
  legal_first_name?: string
  legal_last_name?: string
  email: string
  phone: string
  date_of_birth?: string
  gender?: string
  market?: string
  location_id?: string
  studio: string
  stripe_customer_id?: string
  insurance_intent?: boolean
  designations?: string[]
  meta?: {
    heard_about_us_from: string
    address: Address
  }
  is_mobile_app?: boolean
}

export interface PublicPatient extends PublicPatientRequest {
  external_id: string
  person_id: string
  chart_number: string
  display_name: string
  has_any_appointment: boolean
  patient_type: PatientType
  patient_meta: {
    patient: string
    has_active_covid_alerts: boolean
    requires_covid_questionaire: boolean
    has_insurance: boolean
    needs_insurance: boolean
    needs_payment_info: string
    is_self_pay: boolean
    has_medical_info: boolean
  }
  active_insurance: Insurance
}

export interface PatientResponse extends Patient {
  id: string
  absent_teeth_indices: number[]
  preferences: PatientPreference[]
  active_insurance: Insurance
  medical_alerts: MedicalAlert[]
  preferred_studio: string
  last_bonding_appointment_completed_at: string
  smile_selfie_help_was_viewed_in_web: boolean
  affirm_related_info: AffirmInfo
}

export const fetchPatient = (
  email: string,
  context?: NextPageContext | GetServerSidePropsContext,
  query?: { include_cc_expiration_fields: boolean },
) => {
  const url = queryString.stringifyUrl({
    url: `/api/v2/patients/${email}`,
    query,
  })

  return callApi(url, {
    headers: context?.req
      ? { cookie: context.req.headers.cookie, credentials: 'include' }
      : undefined,
  }) as Promise<PatientResponse>
}

export const fetchPatientAppointments = (
  email: string,
  req?: IncomingMessage,
) => {
  const url = `/api/v2/patients/${email}/appointments`

  return callApi(url, {
    headers: req
      ? { cookie: req.headers.cookie, credentials: 'include' }
      : undefined,
  }) as Promise<Appointment[]>
}

export const createPublicPatient = (data: PublicPatientRequest) => {
  const url = '/api/v2/patients/public'

  return callApi(url, {
    method: 'POST',
    data,
  }) as Promise<PublicPatient>
}

export const createPatientNote = (
  patientToken: string,
  data: CreateNote,
  context?: NextPageContext | GetServerSidePropsContext,
) => {
  const url = `/api/v2/patients/${patientToken}/note`

  return callApi(url, {
    method: 'POST',
    data,
    headers: context?.req
      ? { cookie: context.req.headers.cookie, credentials: 'include' }
      : undefined,
  }) as Promise<any>
}

export const fetchRecallPatient = (
  token: string,
  data?: { last_name: string; phone: string },
) => {
  let url = `/api/patients/recall/${token}`

  if (data) {
    url += `?${queryString.stringify(data)}`
  }

  return callApi(url) as Promise<RecallPatient>
}

export const fetchPublicPatient = (patientId: string) => {
  const url = `/api/v2/patients/${patientId}/public`

  return callApi(url) as Promise<PublicPatient>
}

export const fetchPatientVendorId = (data: { email: string }) => {
  const url = '/api/patients/vendor-id'

  return callApi(url, {
    method: 'PUT',
    data,
  }) as Promise<{ vendor_id: string }>
}

export const updatePatient = (email: string, data: DeepPartial<Patient>) => {
  const url = `/api/v2/patients/${email}`

  return callApi(url, {
    method: 'PUT',
    data,
  }) as Promise<PatientResponse>
}

export const updatePublicPatient = (
  email: string,
  data: DeepPartial<PublicPatientRequest>,
) => {
  const url = `/api/v2/patients/public/${email}`

  return callApi(url, {
    method: 'PUT',
    data,
  }) as Promise<PublicPatient>
}

export const deletePatientAddress = (email: string, addressId: string) => {
  const url = `/api/v2/patients/${email}/address/${addressId}`

  return callApi(url, {
    method: 'DELETE',
  }) as Promise<void>
}

export const partialUpdatePublicPatient = (
  patientId: string,
  data: DeepPartial<PublicPatientRequest>,
) => {
  const url = `/api/v2/patients/public/${patientId}`

  return callApi(url, {
    method: 'PATCH',
    data,
  }) as Promise<PublicPatient>
}

export const publishPatientIdentity = (
  email: string,
  data: { username: string; password: string },
) => {
  const url = `/api/v2/patients/${email}/publish-identity`

  return callApi(url, {
    method: 'POST',
    data,
  }) as Promise<void>
}

export const checkPatientExists = (
  email: string,
  query?: { has_completed_hygiene_appointment: boolean },
) => {
  const url = queryString.stringifyUrl({
    url: `/api/v2/patients/${email}/exists`,
    query,
  })

  return callApi(
    url,
    {
      method: 'GET',
    },
    false,
  ) as AxiosPromise<null>
}
