import { addMonths } from 'date-fns'
import { jwtDecode } from 'jwt-decode'
import mixpanel from 'mixpanel-browser'
import type { NextPageContext } from 'next'
import Router from 'next/router'
import { destroyCookie, parseCookies, setCookie } from 'nookies'
import queryString from 'query-string'
import store from 'store2'
import { Cache } from 'swr'
import { ONE_YEAR, route } from 'lib/constants'
import { fetchPatient } from './api/patients'
import { isIOS } from './utils'

export interface IdTokenPayload {
  email: string
}

interface LoginProps {
  context?: Pick<NextPageContext, 'res'>
  idToken: string
  accessToken: string
  refreshToken: string
}

export const identifyUserForMixpanel = () => {
  const { idToken } = parseCookies()

  if (!idToken) {
    return
  }

  const { 'cognito:username': userId } = jwtDecode(idToken) as any
  mixpanel.identify(userId)
}

const setMixpanelProperties = async (idToken: string) => {
  const { web_view } = parseCookies()
  const isWebView = web_view === 'true'
  const { email } = jwtDecode(idToken) as any
  const patient = await fetchPatient(email)

  setCookie(null, 'patientId', patient.id, {
    maxAge: ONE_YEAR,
    path: '/',
  })

  identifyUserForMixpanel()

  const platform = !isWebView ? 'Web' : isIOS() ? 'iOS' : 'Android'

  mixpanel.register({
    $email: email,
    $phone: patient.person.primary_phone,
    $first_name: patient.person.first_name,
    $last_name: patient.person.last_name,
    $created: patient.created_at,
    'Chart Number': patient.chart_number,
    'Has Insurance': patient.has_insurance,
    'Preferred Studio': patient.preferred_studio,
    'Insurance Payer': patient.active_insurance?.payer,
    Platform: platform,
    'Has Online Bookable Treatment': patient.has_bookable_tx_plan_visit,
  })
  mixpanel.people.set({
    $email: email,
    $phone: patient.person.primary_phone,
    $first_name: patient.person.first_name,
    $last_name: patient.person.last_name,
    $created: patient.created_at,
    'Chart Number': patient.chart_number,
    'Has Insurance': patient.has_insurance,
    'Preferred Studio': patient.preferred_studio,
    'Insurance Payer': patient.active_insurance?.payer,
    Platform: platform,
  })
}

export const login = ({
  context = null,
  idToken,
  accessToken,
  refreshToken,
}: LoginProps) => {
  setCookie(context, 'idToken', idToken, {
    expires: addMonths(new Date(), 1),
    path: '/',
  })
  store.set('accessToken', accessToken)

  if (typeof window !== 'undefined' && (window as any).dataLayer) {
    ;(window as any).dataLayer.push({ sessionType: 'loggedIn' })
  }

  if (refreshToken) {
    setCookie(context, 'refreshToken', refreshToken, {
      expires: addMonths(new Date(), 1),
      path: '/',
    })
  }

  setMixpanelProperties(idToken)

  const decodedToken = idToken ? (jwtDecode(idToken) as any) : ''
  const { email } = decodedToken

  return email
}

export const auth = (ctx = null, redirect = true) => {
  const { idToken } = parseCookies(ctx)
  let loginUrl = route.login

  const navigateToLogin = () => {
    if (typeof window === 'undefined') {
      ctx.res.writeHead(302, { Location: loginUrl })
      ctx.res.end()
    } else {
      Router.push(loginUrl)
    }
  }

  try {
    const { asPath, query } = Router

    loginUrl = queryString.stringifyUrl({
      url: loginUrl,
      query: { email: query.email, redirectURL: asPath },
    })
  } catch (e) {
    navigateToLogin()
  }

  // If there's no id token, it means the user is not logged in.
  if (!idToken && redirect) {
    navigateToLogin()
  }

  return idToken
}

interface LogoutProps {
  context?: Pick<NextPageContext, 'res'>
  path?: string
  cache?: Cache<any>
}

export const logout = async ({
  context = null,
  path = '/',
  cache = null,
}: LogoutProps) => {
  destroyCookie(context, 'idToken', { path: '/' })
  destroyCookie(context, 'refreshToken', { path: '/' })
  destroyCookie(context, 'patientId', { path: '/' })

  if (cache) {
    for (const key of cache.keys()) {
      cache.delete(key)
    }
  }

  // to support logging out from all windows
  if (typeof window !== 'undefined') {
    if ((window as any).dataLayer) {
      ;(window as any).dataLayer.push({ sessionType: 'loggedOut' })
    }

    window.localStorage.setItem('logout', Date.now().toString())

    if (path) {
      Router.push(path)
    }
  }

  mixpanel.reset()
}
