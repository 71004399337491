import { SvgIcon } from '@mui/material'

const TwitterIcon = (props) => (
  <SvgIcon {...props} alt="Twitter">
    <path
      d="M23.1801 4.71L21.0801 3.81L22.1801 1.41L19.6201 2.26C18.8047 1.49993 17.7347 1.07194 16.6201 1.06C15.4274 1.06264 14.2844 1.53759 13.441 2.38094C12.5977 3.22428 12.1227 4.36734 12.1201 5.56V6.56C8.58008 7.29 5.49008 5.36 2.62008 2.06C2.12008 4.72666 2.62008 6.72666 4.12008 8.06L0.830078 7.56C0.957184 8.61919 1.45242 9.60004 2.22926 10.3312C3.00609 11.0623 4.01513 11.4973 5.08008 11.56L2.33008 12.56C3.33008 14.56 5.15008 14.87 7.58008 15.06C5.5939 16.4147 3.23365 17.114 0.830078 17.06C13.5901 22.73 21.0801 14.4 21.0801 7.06V6.23L23.1801 4.71Z"
      fill={props.fill || 'none'}
      stroke={props.htmlColor || '#F9F9F6'}
      strokeWidth={props.strokeWidth || '1.5'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export default TwitterIcon
