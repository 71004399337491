import * as Sentry from '@sentry/nextjs'
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import * as cookie from 'cookie'
import { NextApiRequest, NextApiResponse } from 'next'
import { parseCookies } from 'nookies'
import { route } from 'lib/constants'
import { refreshIdToken } from './api/accounts'
import { login, logout } from './auth'

const isBrowser = () => typeof window !== 'undefined'

const getAuthToken = (config: AxiosRequestConfig, useTendToken = true) => {
  const tokens = isBrowser()
    ? parseCookies(null)
    : cookie.parse((config.headers.cookie || '') as string)

  let token = tokens.idToken
  if (!token && useTendToken) {
    token = process.env.NEXT_PUBLIC_TEND_API_KEY
  }

  return token
}

const attachAuthInterceptor = (
  axiosInstance: AxiosInstance,
  useTendToken = true,
) => {
  axiosInstance.interceptors.request.use((config) => {
    if (!config.headers['Authorization']) {
      const token = getAuthToken(config, useTendToken)
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
    }
    return config
  })
}

const attachErrorInterceptor = (
  axiosInstance: AxiosInstance,
  isEcomm = false,
) => {
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    (error: AxiosError) => {
      const { message, stack, isAxiosError, config, code } = error
      const {
        status,
        statusText,
        headers,
        data: responseData,
      } = error.response || {}
      const { data: requestData } = config || {}

      const err = {
        message,
        stack,
        isAxiosError,
        status,
        statusText,
        headers,
        data: responseData,
        requestData,
        errorCode: code,
      }

      if (status) {
        if (isEcomm || status >= 500) {
          Sentry.captureException(err)
        }

        if (status === 403) {
          logout({ path: route.login })
        }
      }

      return Promise.reject(error.response)
    },
  )
}

// ***
// IDENTITY API CLIENT
// ***
const identityInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_IDENTITY_API_URL,
})
attachAuthInterceptor(identityInstance)
attachErrorInterceptor(identityInstance)

export const callIdentityApi = async (
  url: string,
  config: AxiosRequestConfig = {},
) => {
  const { data } = await identityInstance(url, config)

  return data
}

// ***
// PRIME API CLIENT
// ***
const getRefreshToken = (failedRequest) => {
  const tokens = isBrowser()
    ? parseCookies(null)
    : cookie.parse((failedRequest.config.headers.cookie || '') as string)

  return tokens.refreshToken
}

const refreshAuthLogic = async (failedRequest) => {
  const refreshToken = getRefreshToken(failedRequest)

  const tokenRefreshResponse = await refreshIdToken({
    refreshToken,
  }).catch(() => logout({ path: route.login }))

  if (!tokenRefreshResponse) {
    return
  }

  const { AccessToken: accessToken, IdToken: idToken } =
    tokenRefreshResponse.AuthenticationResult

  login({ accessToken, idToken, refreshToken })
  failedRequest.response.config.headers['Authorization'] = `Bearer ${idToken}`
  return Promise.resolve()
}

const apiInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
})
attachAuthInterceptor(apiInstance)
createAuthRefreshInterceptor(apiInstance, refreshAuthLogic)
attachErrorInterceptor(apiInstance)

export const callApi = async (
  url: string,
  config: AxiosRequestConfig = {},
  unwrap = true,
) => {
  const response = await apiInstance(url, config)

  if (unwrap) {
    const { data } = response
    return data
  } else {
    return response
  }
}

export const callFormDataApi = async (
  url: string,
  config: AxiosRequestConfig = {},
) => {
  apiInstance.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'multipart/form-data'
    return config
  })

  const { data } = await apiInstance(url, config)

  return data
}

export const sendGraphQLRequest = async <T = any>(
  query: string,
  variables?: Record<string, any>,
): Promise<T> => {
  try {
    const response = await apiInstance.post(
      '/api/graphql',
      {
        query,
        variables,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (response.data.error) {
      throw new Error(response.data.error[0].message)
    }

    return response.data.data
  } catch (error) {
    console.error('Error sending GraphQL request:', error)
    throw error
  }
}

// ***
// NEXT API CLIENT
// ***
const nextInstance = axios.create({
  headers: {
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_TEND_API_KEY}`,
  },
})

export const callNextApi = async (
  url: string,
  config: AxiosRequestConfig = {},
) => {
  const { data } = await nextInstance(url, config)
  return data
}

export const withApiKey = (handler) => {
  return async (req: NextApiRequest, res: NextApiResponse) => {
    if (
      req.headers.authorization !==
      `Bearer ${process.env.NEXT_PUBLIC_TEND_API_KEY}`
    ) {
      return res.status(401).send('Unauthorized')
    }

    return await handler(req, res)
  }
}
