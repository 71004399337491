import { route } from 'lib/constants'
import { callIdentityApi } from '../api'

export const createAccount = (data: {
  username: string
  password: string
  first_name?: string
  last_name?: string
  primary_phone?: string
  preferred_name?: string
  registrationType?: string
}) => {
  const url = '/register'

  return callIdentityApi(url, {
    method: 'POST',
    data,
  })
}

export const logInAccount = (data: { username: string; password: string }) => {
  const url = route.login

  return callIdentityApi(url, {
    method: 'POST',
    data,
  }) as Promise<{ idToken: string; accessToken: string; refreshToken: string }>
}

export const resetPassword = (data: { username: string }) => {
  const url = '/forgot-password'

  return callIdentityApi(url, {
    method: 'POST',
    data,
  })
}

export const resendConfirmation = (data: { username: string }) => {
  const url = '/resend-confirmation'

  return callIdentityApi(url, {
    method: 'POST',
    data,
  })
}

export const resendEmailChangeConfirmation = (data: {
  username: string
  accessToken: string
}) => {
  const url = '/resend-email-change-confirmation'

  return callIdentityApi(url, {
    method: 'POST',
    data,
  })
}

export const changePassword = (data: {
  accessToken: string
  oldPassword: string
  newPassword: string
}) => {
  const url = '/change-password'

  return callIdentityApi(url, {
    method: 'POST',
    data,
  })
}

export const refreshIdToken = (data: { refreshToken: string }) => {
  const url = '/refresh-token'

  return callIdentityApi(url, {
    method: 'POST',
    data,
  }) as Promise<{
    ChallengeParameters: Record<string, unknown>
    AuthenticationResult: {
      AccessToken: string
      ExpiresIn: number
      TokenType: string
      IdToken: string
    }
  }>
}

export const confirmForgotPassword = (data: {
  username: string
  newPassword: string
  confirmationCode: string
}) => {
  const url = '/confirm-forgot-password'

  return callIdentityApi(url, {
    method: 'POST',
    data,
  })
}

export const confirmSignup = (data: {
  username: string
  confirmationCode: string
}) => {
  const url = '/confirm-signup'

  return callIdentityApi(url, {
    method: 'POST',
    data,
  })
}

export const confirmEmailChange = (data: {
  accessToken: string
  confirmationCode: string
}) => {
  const url = '/confirm-email-change'

  return callIdentityApi(url, {
    method: 'POST',
    data,
  })
}

export const fetchUserExist = (data: { username: string }) => {
  const url = '/user-exist'

  return callIdentityApi(url, {
    method: 'POST',
    data,
  }) as Promise<{ exists: boolean }>
}

export const fetchVerifyStatus = (data: { username: string }) => {
  const url = '/verification-status'

  return callIdentityApi(url, {
    method: 'POST',
    data,
  }) as Promise<{
    exists: boolean
    emailVerified: boolean
    emailChanged: boolean
  }>
}
