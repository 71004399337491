import { SvgIcon } from '@mui/material'

const FacebookIcon = (props) => (
  <SvgIcon {...props} alt="Facebook">
    <path
      d="M18.1301 7.74999H13.8701V5.83999C13.8561 5.70051 13.8716 5.55965 13.9155 5.42653C13.9594 5.29342 14.0309 5.17103 14.1252 5.0673C14.2195 4.96358 14.3345 4.88084 14.4628 4.82445C14.5912 4.76806 14.7299 4.73929 14.8701 4.73999H17.8701V0.73999H13.5401C9.61012 0.73999 8.87012 3.73999 8.87012 5.59999V7.74999H5.87012V11.75H8.87012V23.25H13.8701V11.75H17.7201L18.1301 7.74999Z"
      fill={props.fill || 'none'}
      stroke={props.htmlColor || '#F9F9F6'}
      strokeWidth={props.strokeWidth || '1.5'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export default FacebookIcon
