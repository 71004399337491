import { Dispatch, SetStateAction, useState } from 'react'
import useSWR, { SWRConfiguration, SWRResponse } from 'swr'
import { fetchPatient, PatientResponse } from 'lib/api/patients'
import { getIdTokenPayloadFromCookies } from 'lib/utils'

const usePatientFetcher = (
  config?: SWRConfiguration<PatientResponse>
): SWRResponse<PatientResponse, any> & {
  updatePatient: Dispatch<SetStateAction<string | number | undefined>>
} => {
  const { email } = getIdTokenPayloadFromCookies() ?? {}
  const [updatePatientValue, updatePatient] = useState()

  const patientFetcher = async () => await fetchPatient(email)

  return {
    ...useSWR(
      email ? `/api/v2/patients/${email}/${updatePatientValue}` : null,
      patientFetcher,
      config
    ),
    updatePatient,
  }
}

export default usePatientFetcher
